.mobileStore{
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    & svg{
        fill: #000;
        cursor: pointer;
        @media (max-width: 400px) {
            width: 100%;
        }
    }
}